.left-right-wrapper {
  display: flex;
  /* flex-direction: column; */
}
.left-side {
  width: 30%;
  background: #4682b4;
  background: #4169e1;
  background: navy;
  background: navy;
  background: #7c99ab;
  background: #072a49;
  background: rgb(5, 5, 5);
  background: #072a49;

  height: 100vh;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  /* box-shadow: 1px 1px 1px gray; */
}
.left-side-header {
  color: white;
  color: rgb(202, 206, 206);

  margin-bottom: 20px;
  margin-top: 40px;
  font-family: "Montserrat";
  font-size: 1.2rem;
}
.left-side-p {
  color: white;
  color: rgb(202, 206, 206);
  font-family: "Roboto";
  font-size: 1.2rem;
  margin-bottom: 30px;
}
.middle {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: navy;
  background: hsl(203, 22%, 58%);
  background: #072a49;
  background: #7c99ab;
}
.middle-img-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.middle-img {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 1px 1px 1px gray; */
  width: 350px;
  margin-left: 25px;
}
.right-side {
  width: 60%;
  height: 100vh;
  margin-left: 10%;
}
.right-side-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  margin-left: 50px;
}
.first-name {
  font-family: "Montserrat";
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
  color: #072a49;
  color: var(--other-color);
  color: var(--color);
}
.last-name {
  font-family: "Montserrat";
  font-size: 2.5rem;
  font-weight: bold;
  color: #072a49;
  color: var(--color);
  color: var(--color);

  margin: 0;
  margin-top: 10px;
}
.role {
  font-family: "Roboto";
  font-size: 1.2rem;
  margin: 0;
  margin-top: 20px;
  color: #072a49;
  color: var(--color);
}
.description {
  font-family: "Roboto";
  font-size: 1.2rem;
  margin: 0;
  margin-top: 50px;
  width: 75%;
  color: #072a49;
}
.btn-wrapper {
  font-family: "Roboto";
  font-size: 1.2rem;
  margin: 0;
  margin-top: 50px;
  width: 75%;
  display: flex;
}
.portfolio-btn {
  /* border-radius: 8px; */
  color: rgb(202, 206, 206);
  color: #fff;
  background-color: navy;
  background-color: navy;
  background-color: #072a49;

  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  margin: 1em;
  margin-left: 0;
  letter-spacing: 0.2em;
  width: 25%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 1px #072a49;
  border: 1px solid #072a49;
  text-decoration: none;
}
.resume-btn {
  /* border-radius: 8px; */
  border: 1px solid#072a49;
  /* color: #072a49; */

  font-size: 1.2rem;
  cursor: pointer;
  margin: 1em;
  margin-left: 10%;
  letter-spacing: 0.2em;
  width: 25%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 1px #072a49;
  color: #072a49;
  color: var(--color);
  text-decoration: none;
}
.icon {
  margin-right: 25px;
}
.bottom {
  position: absolute;
  bottom: 50px;
}
.top {
  position: absolute;
  top: 30px;
  left: 50px;
}
.top2 {
  position: absolute;
  top: 30px;
  right: 50px;
}
.hamburger {
  cursor: pointer;
}
.hamburger-content {
  visibility: hidden;
  position: fixed;
  height: 100vh;
  width: 0;
  background: white;
  background: #7c99ab;
  z-index: 99;
  margin: 0;
}
.hamburger-links {
  color: black;
  color: #072a49;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  margin-top: 100px;
  margin-right: 30px;
}
.hamburger-link {
  margin-bottom: 10px;
  font-family: "Roboto";
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  color: #072a49;
  text-decoration: none;
}
.hamburger-link:hover {
  text-decoration: underline;
}
.link-p {
  margin-left: 10px;
}
.close-btn {
  /* width: 100%; */
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  color: #072a49;
}
.close-btn-wrapper {
  /* margin-top: 30px;
  margin-left: 30px;
  border: 1px solid red; */
}
@media (max-width: 1260px) {
  .middle-img {
    width: 300px;
    margin-left: 70px;
    height: 450px;
  }
  .portfolio-btn {
    font-size: 1rem;
  }
  .resume-btn {
    font-size: 1rem;
  }
  .left-side {
    padding-left: 20px;
  }
  .left-side-p {
    font-size: 1rem;
  }
  .left-side-header {
    font-size: 1rem;
  }
}
@media (max-width: 1000px) {
  .left-right-wrapper {
    display: flex;
    flex-direction: column;
  }
  .left-side {
    width: 100%;

    background: #072a49;

    height: 100px;
    padding-left: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    /* box-shadow: 1px 1px 1px gray; */
  }
  .left-side-p {
    display: none;
  }
  .left-side-header {
    display: none;
  }
  .middle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .middle-img {
    width: 250px;
    height: 300px;
    margin: 0;
    margin-top: 20px;
  }
  .right-side {
    width: 100%;
    /* height: 100px; */
    margin-left: 0%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 300px;
  }
  .right-side-wrapper {
    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .btn-wrapper {
    width: 100%;

    /* margin: 0;
    padding: 0; */
    /* border: 1px solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }
  .top {
    left: 20px;
    top: 25px;
  }
  .top2 {
    right: 20px;
    top: 25px;
  }
  .first-name {
    margin-top: 75px;
    font-size: 2rem;
  }
  .last-name {
    font-size: 2rem;
  }
  .portfolio-btn {
    margin-right: 10px;
  }
  .resume-btn {
    margin-left: 10px;
  }
  .role {
    font-size: 1rem;
  }
  .container {
    padding-bottom: 250px;
  }
  .home-container {
    height: 100%;
    padding-bottom: 150px;
  }
  .hamburger-link {
    font-size: 1rem;
  }
  .close-btn-wrapper {
    margin-left: 10px;
  }
}
