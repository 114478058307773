.container {
  margin: 0;
  //   background: rgb(202, 206, 206);
  height: 100vh;
  margin-bottom: 400px;
  background-color: var(--background-color);
  color: var(--color);

  .page-header {
    display: flex;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 50px;
    font-size: 2.5rem;
    font-family: "Montserrat";
    color: navy;
    color: black;
    color: var(--color);

    margin: 0;
    margin-left: 20%;
    margin-right: 20%;
    text-align: center;
    @media (max-width: 1000px) {
      padding-top: 150px;
    }
  }
  .about-me-p {
    font-family: "Roboto";
    font-size: 1.2rem;
    margin-left: 20%;
    margin-right: 20%;
  }
  .about-link {
    color: black;
    color: var(--color);
  }
  .blog-links {
    margin-left: 20%;
    margin-right: 20%;
    font-family: "Roboto";
    font-size: 1.2rem;
    .blog-link {
      display: flex;
      justify-content: center;
      color: black;
      color: var(--color);

      padding: 2%;
      border: 1px solid var(--color);
      text-decoration: none;
      // box-shadow: 1px 1px 1px gray;
    }
    #view-all-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      #view-all-link {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 100px;
        color: black;
        color: var(--color);
      }
    }
  }
  #three {
    padding-bottom: 250px;
  }
  .project {
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 100px;
    margin-bottom: 200px;
    display: flex;
    @media (max-width: 800px) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 100px;
    }
    .company-link {
      color: var(--color);
    }
    .project-left {
      width: 40%;
      @media (max-width: 800px) {
        width: 100%;
        justify-content: center;
        align-items: center;
      }
      .project-number {
        color: #fff;
        background-color: #072a49;
        font-size: 1.2rem;
        border: 0;
        margin: 0;
        margin-bottom: 1em;
        margin-left: 0;
        letter-spacing: 0.2em;
        font-family: "Roboto";
        padding-left: 5px;
      }
      .project-title {
        font-family: "Montserrat";
        font-size: 2rem;
        font-weight: bold;
        margin: 0;
      }
      .project-description {
        font-size: 1.2rem;
        font-family: "Roboto";
        margin-top: 20px;
        margin-bottom: 50px;
      }
    }

    // display: inline-block;
    // outline: 0;
    // cursor: pointer;
    // border: none;
    // padding: 0 56px;
    // height: 45px;
    // line-height: 45px;
    // border-radius: 7px;
    // background-color: #0070f3;
    // color: white;
    // font-weight: 400;
    // font-size: 16px;
    // box-shadow: 0 4px 14px 0 rgb(0 118 255 / 39%);
    // transition: background 0.2s ease, color 0.2s ease, box-shadow 0.2s ease;
    // :hover {
    //   background: rgba(0, 118, 255, 0.9);
    //   box-shadow: 0 6px 20px rgb(0 118 255 / 23%);
    // }

    // display: inline-block;
    // outline: 0;
    // border: 0;
    // cursor: pointer;
    // border-radius: 8px;
    // padding: 14px 24px 16px;
    // font-size: 18px;
    // font-weight: 700;
    // line-height: 1;
    // background: transparent;
    // color: #000000;
    // box-shadow: 0 0 0 3px #000000 inset;
    // :hover {
    //   transform: translateY(-2px);
    // }

    .details-btn {
      display: inline-block;
      outline: 0;
      border: 0;
      cursor: pointer;
      border-radius: 8px;
      padding: 14px 24px 16px;
      font-size: 18px;
      // font-weight: 500;
      line-height: 1;
      background: #072a49;
      color: black;
      box-shadow: 0 0 0 3px #072a49 inset;

      .details-link {
        font-size: 1.5rem;
        font-family: "Roboto";
        color: white;
        text-decoration: none;
        cursor: pointer;
      }
    }
    .project-middle {
      width: 20%;
    }
    .project-right {
      width: 40%;
      @media (max-width: 800px) {
        width: 100%;
        margin-bottom: 50px;
        margin-top: 50px;
      }
    }
    #project-img-wrapper {
    }
    #project-img {
      width: 150%;
      height: auto;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
    .img-caption {
      width: 150%;
      font-size: 0.8rem;
      font-family: "Roboto";
      display: flex;
      justify-content: center;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
  }
}
